* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f9fafb;
}

a {
  text-decoration: none;
}

.contracts-form .Mui-disabled {
  color: black !important;
}

.contracts-form input.Mui-disabled {
  background-color: #ebebeb;
  padding-left: 5px;
}

.contracts-form textarea.Mui-disabled {
  background-color: #ebebeb;
  padding-left: 5px;
}

.contracts-form label.Mui-disabled {
  font-weight: 800;
}

.contracts-form label {
  font-weight: 800;
}

.expenses-form .MuiCheckbox-colorSecondary.Mui-disabled {
  color: inherit;
  padding: 0;
}